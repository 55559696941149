<template>
  <div class="waiting-layer">
    <div>
      <img class="waiting" src="../../assets/neo-spinner.svg" alt="" />
      <div class="text">{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaitingLayer',
  props: {
    text: String,
  },
};
</script>

<style lang="scss" scoped>
.waiting-layer {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
  div {
    margin: auto;
    text-align: center;
  }
  img {
    margin: auto;
  }
  .text {
    color: var(--color-primary);
    font-weight: bold;
  }
}
</style>
