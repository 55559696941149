<template>
  <ul class="checkout-steps">
    <template v-for="(step, i) in steps">
      <li class="checkout-steps__step" :key="i">
        <div
          v-if="step.text"
          class="checkout-steps__step-button"
          :class="{
            'checkout-steps__step-button--active':
              step.stepOrder === stepActive,
            'checkout-steps__step-button--completed':
              stepActive > step.stepOrder || (stepActive === steps.length - 1 && complete),
          }"
        >
          <span>{{ step.text }}</span>
        </div>
        <div
          v-else
          class="checkout-steps__step-button empty-text"
          :class="{
            'checkout-steps__step-button--active':
              step.stepOrder === stepActive,
            'checkout-steps__step-button--completed':
              stepActive > step.stepOrder || (stepActive === steps.length - 1 && complete),
          }"
        >
          <span>{{ step.text }}</span>
        </div>
      </li>
    </template>
  </ul>
</template>

<script>
export default {
  name: "CheckoutSteps",
  props: {
    steps: Array,
    stepActive: Number,
    complete: { type: Boolean, default: true },
  },
};
</script>

<style lang="scss" scoped>
.empty-text{
  padding: 4px 0px 4px 4px !important;
}
.checkout-steps {
  position: relative;
  $this: &;
  margin: 8px 4px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  counter-reset: section;
  // line
  background-image: repeating-linear-gradient(
    0deg,
    #000000,
    #000000 1px,
    transparent 1px,
    transparent
  );
  background-size: 100% 19px;
  background-color: rgba(255, 255, 255, 0);
  @media only screen and (max-width: 600px) {
    margin: 8px 0 24px;
    flex-wrap: wrap;
  }
  &__step {
    margin-top: 2px;
  }
  &__step-button {
    width: auto;
    padding: 4px 8px 4px 4px;
    display: inline-block;
    counter-increment: section;
    font-weight: 400;
    border-radius: 4px;
    transition: all 0.4s linear;
    font-weight: 600;
    position: relative;
    border-radius: 45px;
    background: var(--color-grey);

    &--active {
      background: var(--color-primary);
    }
    &--completed {
      background: var(--color-white);
    }
    span {
      display: flex;
      align-items: center;
      @media only screen and (max-width: 600px) {
        font-size: 10px;
      }
      &:before {
        margin-right: 4px;
        display: block;
        text-align: center;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        line-height: 24px;
        background-color: var(--color-primary);
        content: counter(section);
      }
    }
    &--active {
      > span:before {
        background-color: var(--color-white) !important;
      }
    }
    &--completed {
      > span:before {
        background-color: var(--color-success) !important;
        color: var(--color-white);
      }
    }
  }
}
</style>
