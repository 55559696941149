var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loadingFrames)?_c('WaitingLayer',{attrs:{"text":_vm.$t('generatingFrames')}}):_vm._e(),(_vm.isWaitingPay)?_c('WaitingLayer',{attrs:{"text":_vm.$t('processingPayment')}}):_vm._e(),_c('two-columns-center',{staticClass:"min-checkout-height"},[_c('template',{slot:"col-1"},[(_vm.imageLoaded)?_c('CheckoutWork',{staticClass:"checkout-frames__work",attrs:{"id":_vm.id,"img":_vm.canvasImg,"title":_vm.title,"artist":_vm.artist,"coordinates":_vm.coordinateClicked}},[(_vm.coordinateClicked)?_c('div',[_vm._v(_vm._s(_vm.coordinateClicked))]):_vm._e()]):_vm._e(),(_vm.$store.state.countdown > '0:00')?_c('count-down',{staticClass:"mt-4 ma-auto",attrs:{"ending":_vm.$store.state.countdown === '0:01',"value":_vm.$store.state.countdown}}):_vm._e()],1),_c('template',{slot:"col-2"},[_c('div',{staticClass:"checkout-flow"},[_c('CheckoutSteps',{attrs:{"steps":_vm.steps,"stepActive":_vm.stepActive}}),_c('div',{staticClass:"checkout-flow-wrapper-steps"},[_c('div',{staticClass:"checkout-flow__layer",class:{
              'checkout-flow__layer--visible':
                _vm.steps[_vm.stepActive].stepOrder === 0,
            }},[_c('Checkout2Summary',{staticClass:"mb-4",attrs:{"selectedFrames":_vm.blockedFrames,"totalValue":_vm.subtotal,"currency":_vm.currency,"id":_vm.id},on:{"coordinateClicked":_vm.getCoordinate}})],1),_c('div',{staticClass:"checkout-flow__layer",class:{
              'checkout-flow__layer--visible':
                _vm.steps[_vm.stepActive].stepOrder === 1,
            }},[_c('CheckoutTAndC',{on:{"check":_vm.termAndCondAccept}})],1),_c('div',{staticClass:"checkout-flow__layer",class:{
              'checkout-flow__layer--visible':
                _vm.steps[_vm.stepActive].stepOrder === 2,
            }},[_c('Checkout3PaymentDetails',{attrs:{"id":_vm.id,"frameValue":_vm.frameValue,"subtotal":_vm.subtotal,"tax":_vm.tax,"taxValue":_vm.taxValue,"totalValue":_vm.totalValue,"currency":_vm.currency,"nFrames":_vm.nFrames,"assignedFrames":_vm.blockedFrames,"session":_vm.session},on:{"pay":_vm.paymentSuccessful,"waitingPay":_vm.handleWaitingPay,"paymentError":_vm.showErrorMessage}})],1),_c('div',{staticClass:"checkout-flow__layer",class:{
              'checkout-flow__layer--visible':
                _vm.steps[_vm.stepActive].stepOrder === 3,
            }},[_c('CommonSuccessCheckout',{staticClass:"mb-4",attrs:{"text":_vm.paymentResultMessage,"color":_vm.messageType,"icon":_vm.resultIcon}})],1)]),_c('div',{staticClass:"checkout-flow__footer"},[(_vm.stepActive !== 3)?[_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.backwardStep}},[_vm._v(_vm._s(_vm.$t("back")))]),(_vm.stepActive !== 2)?_c('v-btn',{attrs:{"disabled":_vm.stepActive === 1 && !_vm.termAndCondAccepted,"color":"primary"},on:{"click":_vm.forwardStep}},[_vm._v(" "+_vm._s(_vm.$t("next"))+" ")]):_vm._e()]:[_c('div',{staticClass:"checkout-confirm-buttons"},[_c('v-btn',{attrs:{"outlined":"","to":{ name: 'artwork' },"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("backToArtwork"))+" ")]),_c('v-btn',{attrs:{"color":"primary","to":{ name: 'summary', params: { tabId: 'transactions' } }}},[_vm._v(" "+_vm._s(_vm.$t("checkTransactions"))+" ")])],1)]],2)],1)])],2),(_vm.showAdviseModal)?_c('NeoModal',{attrs:{"max-width":440,"title-header":"Advise"},on:{"close":_vm.closeAdviseModal}},[_c('v-card-text',[_c('p',{staticClass:"text-center pa-4"},[_vm._v(" "+_vm._s(_vm.$t("abandonCartModal"))+" ")])]),_c('template',{slot:"actions"},[_c('div',{staticClass:"checkout-advise-modal-footer"},[_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.cancelBooking}},[_vm._v(" "+_vm._s(_vm.$t("loseFrames"))+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.closeAdviseModal}},[_vm._v(_vm._s(_vm.$t("keepBuying")))])],1)])],2):_vm._e(),(_vm.framesModal)?_c('FramesWorkDialog',{attrs:{"id":_vm.id,"imageURL":_vm.canvasImg,"initialCoordinate":_vm.coordinateClicked,"frameList":_vm.blockedFrames,"coordinateIndex":_vm.coordinateIndex},on:{"close":function($event){_vm.framesModal = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }