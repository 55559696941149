var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"checkout-steps"},[_vm._l((_vm.steps),function(step,i){return [_c('li',{key:i,staticClass:"checkout-steps__step"},[(step.text)?_c('div',{staticClass:"checkout-steps__step-button",class:{
          'checkout-steps__step-button--active':
            step.stepOrder === _vm.stepActive,
          'checkout-steps__step-button--completed':
            _vm.stepActive > step.stepOrder || (_vm.stepActive === _vm.steps.length - 1 && _vm.complete),
        }},[_c('span',[_vm._v(_vm._s(step.text))])]):_c('div',{staticClass:"checkout-steps__step-button empty-text",class:{
          'checkout-steps__step-button--active':
            step.stepOrder === _vm.stepActive,
          'checkout-steps__step-button--completed':
            _vm.stepActive > step.stepOrder || (_vm.stepActive === _vm.steps.length - 1 && _vm.complete),
        }},[_c('span',[_vm._v(_vm._s(step.text))])])])]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }