<template>
  <div>
    <WaitingLayer v-if="loadingFrames" :text="$t('generatingFrames')" />
    <WaitingLayer v-if="isWaitingPay" :text="$t('processingPayment')" />
    <two-columns-center class="min-checkout-height">
      <template slot="col-1">
        <CheckoutWork
          v-if="imageLoaded"
          class="checkout-frames__work"
          :id="id"
          :img="canvasImg"
          :title="title"
          :artist="artist"
          :coordinates="coordinateClicked"
        >
          <div v-if="coordinateClicked">{{ coordinateClicked }}</div>
        </CheckoutWork>
        <count-down
          class="mt-4 ma-auto"
          :ending="$store.state.countdown === '0:01'"
          v-if="$store.state.countdown > '0:00'"
          :value="$store.state.countdown"
        ></count-down>
      </template>
      <template slot="col-2">
        <div class="checkout-flow">
          <CheckoutSteps :steps="steps" :stepActive="stepActive" />
          <div class="checkout-flow-wrapper-steps">
            <div
              class="checkout-flow__layer"
              :class="{
                'checkout-flow__layer--visible':
                  steps[stepActive].stepOrder === 0,
              }"
            >
              <Checkout2Summary
                :selectedFrames="blockedFrames"
                :totalValue="subtotal"
                :currency="currency"
                :id="id"
                @coordinateClicked="getCoordinate"
                class="mb-4"
              />
            </div>

            <div
              class="checkout-flow__layer"
              :class="{
                'checkout-flow__layer--visible':
                  steps[stepActive].stepOrder === 1,
              }"
            >
              <CheckoutTAndC @check="termAndCondAccept" />
            </div>

            <div
              class="checkout-flow__layer"
              :class="{
                'checkout-flow__layer--visible':
                  steps[stepActive].stepOrder === 2,
              }"
            >
              <Checkout3PaymentDetails
                :id="id"
                :frameValue="frameValue"
                :subtotal="subtotal"
                :tax="tax"
                :taxValue="taxValue"
                :totalValue="totalValue"
                :currency="currency"
                :nFrames="nFrames"
                :assignedFrames="blockedFrames"
                :session="session"
                @pay="paymentSuccessful"
                @waitingPay="handleWaitingPay"
                @paymentError="showErrorMessage"
              />
            </div>

            <div
              class="checkout-flow__layer"
              :class="{
                'checkout-flow__layer--visible':
                  steps[stepActive].stepOrder === 3,
              }"
            >
              <CommonSuccessCheckout
                class="mb-4"
                :text="paymentResultMessage"
                :color="messageType"
                :icon="resultIcon"
              />
            </div>
          </div>
          <div class="checkout-flow__footer">
            <template v-if="stepActive !== 3">
              <v-btn outlined color="primary" @click="backwardStep">{{
                $t("back")
              }}</v-btn>
              <v-btn
                v-if="stepActive !== 2"
                :disabled="stepActive === 1 && !termAndCondAccepted"
                color="primary"
                @click="forwardStep"
              >
                {{ $t("next") }}
              </v-btn>
            </template>
            <template v-else>
              <div class="checkout-confirm-buttons">
                <v-btn outlined :to="{ name: 'artwork' }" color="primary">
                  {{ $t("backToArtwork") }}
                </v-btn>
                <v-btn
                  color="primary"
                  :to="{ name: 'summary', params: { tabId: 'transactions' } }"
                >
                  {{ $t("checkTransactions") }}
                </v-btn>
              </div>
            </template>
          </div>
        </div>
      </template>
    </two-columns-center>
    <NeoModal
      v-if="showAdviseModal"
      :max-width="440"
      title-header="Advise"
      @close="closeAdviseModal"
    >
      <v-card-text>
        <p class="text-center pa-4">
          {{ $t("abandonCartModal") }}
        </p>
      </v-card-text>
      <template slot="actions">
        <div class="checkout-advise-modal-footer">
          <v-btn @click="cancelBooking" outlined color="primary">
            {{ $t("loseFrames") }}
          </v-btn>
          <v-btn color="primary" @click="closeAdviseModal">{{
            $t("keepBuying")
          }}</v-btn>
        </div>
      </template>
    </NeoModal>
    <FramesWorkDialog
      v-if="framesModal"
      :id="id"
      :imageURL="canvasImg"
      :initialCoordinate="coordinateClicked"
      :frameList="blockedFrames"
      :coordinateIndex="coordinateIndex"
      @close="framesModal = false"
    />
  </div>
</template>

<script>
import FramesDS from "@/services/FramesDS";
import ArtworkDS from "@/services/ArtworkDS";
import helpers from "@/mixins/helpers.js";
import WaitingLayer from "@/components/Molecules/WaitingLayer";
import { analytics } from "@/helpers/firebase";

import TwoColumnsCenter from "@/components/Templates/TwoColumnsCenter";
import CheckoutSteps from "@/components/Organisms/CheckoutSteps";

const CheckoutWork = () =>
  import(
    /* webpackChunkName: "CheckoutWork" */ "@/components/Organisms/CheckoutWork"
  );
const CheckoutTAndC = () =>
  import(
    /* webpackChunkName: "CheckoutTAndC" */ "@/components/Organisms/CheckoutTAndC"
  );
const Checkout2Summary = () =>
  import(
    /* webpackChunkName: "Checkout2Summary" */ "@/components/Organisms/Checkout2Summary"
  );
const Checkout3PaymentDetails = () =>
  import(
    /* webpackChunkName: "Checkout3PaymentDetails" */ "@/components/Organisms/Checkout3PaymentDetails"
  );
const CommonSuccessCheckout = () =>
  import(
    /* webpackChunkName: "CommonSuccessCheckout" */ "@/components/Organisms/CommonSuccessCheckout"
  );
const CountDown = () =>
  import(
    /* webpackChunkName: "CountDown" */ "@/components/Molecules/CountDown"
  );
const NeoModal = () =>
  import(/* webpackChunkName: "NeoModal" */ "@/components/Molecules/NeoModal");
const FramesWorkDialog = () =>
  import(
    /* webpackChunkName: "FramesWorkDialog" */ "@/components/Organisms/FramesWorkDialog"
  );

export default {
  name: "vCheckout",
  mixins: [helpers],
  components: {
    TwoColumnsCenter,
    CheckoutSteps,
    CheckoutWork,
    CheckoutTAndC,
    Checkout2Summary,
    Checkout3PaymentDetails,
    CommonSuccessCheckout,
    CountDown,
    NeoModal,
    WaitingLayer,
    FramesWorkDialog,
  },
  props: {
    id: String,
    quadrant: Array,
    img: String,
    quantity: Number,
    qx: { type: Number, default: 0 },
    qy: { type: Number, default: 0 },
  },
  data() {
    return {
      messageType: "success",
      resultIcon: "mdi-check-circle",
      currentValue: 1000,
      coordinateIndex: 0,
      imageLoaded: false,
      loadingFrames: true,
      nFrames: 0,
      frameValue: 0,
      subtotal: 0,
      tax: 0.1,
      taxValue: 0,
      totalValue: 0,
      currency: "",
      canvasImg: "",
      e1: 1,
      stepActive: 0,
      // steps: [
      //   { text: 'Summary', stepOrder: 0 },
      //   { text: 'T&C', stepOrder: 1 },
      //   { text: 'Payment', stepOrder: 2 },
      //   { text: 'Confirmation', stepOrder: 3 },
      // ],
      paymentDetails: false,
      coordinateClicked: null,
      termAndCondAccepted: false,
      artist: "",
      title: "",
      showAdviseModal: false,
      answer: false,
      to: "/",
      blockedFrames: [{ frame: "", value: "", currency: "jpy" }],
      empty: false,
      subFrames: undefined,
      subTimeout: undefined,
      subscriberCalls: 0,
      framesModal: false,
      isWaitingPay: false,
      paymentDone: false,
      transactionState: -1,
      session: "",
      paymentResultMessage: "",
      ongoing: false,
      timeoutInterval: 0,
    };
  },
  created() {
    this.framesModal = false;
  },
  computed: {
    steps() {
      return [
        { text: this.$t("summary"), stepOrder: 0 },
        { text: this.$t("tnc"), stepOrder: 1 },
        { text: this.$t("payment"), stepOrder: 2 },
        { text: this.$t("confirmation"), stepOrder: 3 },
      ];
    },
  },
  methods: {
    modalResponse(e) {
      console.log(e);
    },
    showErrorMessage(message) {
      this.paymentResultMessage = message;
      this.paymentDone = true;
      this.messageType = "error";
      this.resultIcon = "mdi-close-circle";
      if (this.subTimeout) this.subTimeout.off("value");
      if (this.subFrames) this.subFrames.off("value");
      this.$store.commit("endCountdown");
      this.stepActive = 3;
    },
    paymentSuccessful(payment) {
      this.paymentResultMessage = this.$t("paymentSuccess");
      this.paymentDone = true;
      if (this.subTimeout) this.subTimeout.off("value");
      if (this.subFrames) this.subFrames.off("value");
      this.$store.commit("ALERT_MESSAGE", "");

      this.stepActive = 3;

      analytics.logEvent("purchase", {
        value: this.totalValue,
        tax: this.taxValue,
        affiliation: "NEO ONE ART",
        currency: payment.currency,
        transaction_id: payment.id,
        items: [this.gaItems],
      });
    },
    forwardStep() {
      this.stepActive += 1;
    },
    backwardStep() {
      if (this.stepActive === 0) {
        this.$router.go(-1);
      } else {
        this.stepActive -= 1;
      }
    },
    getCoordinate(value) {
      this.coordinateClicked = value;
      this.blockedFrames.some((frame, index) => {
        if (frame.x === value[0] && frame.y === value[1]) {
          this.coordinateIndex = index;
        }
      });
      this.framesModal = true;
    },
    getFrameList(value) {
      this.availableFrames = value;
    },
    disabledNext() {
      return this.stepActive === 1;
    },
    termAndCondAccept(value) {
      this.termAndCondAccepted = value;
      analytics.logEvent("begin_checkout", {
        item_id: this.id,
        item_name: `${this.title} by ${this.artist}`,
        currency: this.currency,
        value: this.subtotal,
        items: [this.gaItems],
      });
    },
    closeAdviseModal() {
      this.showAdviseModal = false;
    },
    openAdviseModal() {
      // if (this.blockedFrames.length === 0) return;
      this.showAdviseModal = true;
    },
    cancelBooking() {
      if (this.subTimeout) this.subTimeout.off("value");
      if (this.subFrames) this.subFrames.off("value");
      this.answer = true;
      FramesDS.unblockFrames(this.$store.state.user.uid, this.session);
      this.$store.commit("endCountdown");
      this.showAdviseModal = false;
      this.$store.commit("ALERT_MESSAGE", "");
      this.$router.push(this.to);
    },
    handleWaitingPay(isWaiting) {
      this.isWaitingPay = isWaiting;
    },
    getBlockedFrames() {
      this.subFrames = FramesDS.getUserBlockedFrames(
        this.$store.state.user.uid,
        this.session,
        this.id
      );

      this.subscriberCalls = 0;
      // Subscribe for blocked frames data changes
      this.subFrames.on("value", (snapshot) => {
        const frames = snapshot.val();

        this.subscriberCalls += 1;
        if (frames !== null && this.transactionState === 6) {
          this.blockedFrames = [];
          this.subtotal = 0;
          this.nFrames = 0;
          this.gaItems = {};
          for (let key in frames) {
            if (this.loadingFrames) this.loadingFrames = false;
            const x = frames[key].x;
            const y = frames[key].y;

            frames[key].frame = `${process.env.VUE_APP_IMAGES_URL}Frames%2F${
              this.id
            }%2F${this.id}_${this.leftFillNum(x, 3)}_${this.leftFillNum(
              y,
              3
            )}.jpg`;
            frames[key].frameId = key;
            frames[key].frameIdPublic = `${this.artist
              .match(/\b\w/g)
              .join("")}${this.title
              .match(/\b\w/g)
              .join("")}${this.leftFillNum(x, 3)}${this.leftFillNum(y, 3)}`;
            frames[key].coordinates = [x, y];
            frames[key].coordinates_formatted = `${x}x${y}`;
            // For google analytics event

            // ------------------------------
            frames[key].currentValue = this.currentValue;
            frames[key].currency = this.currency;
            this.blockedFrames.push(frames[key]);
            this.subtotal += this.currentValue;
            frames[key].price = frames[key].currentValue;
            this.nFrames += 1;
          }
          this.taxValue = this.tax * this.subtotal;
          this.totalValue = this.subtotal + this.taxValue;
          if (this.nFrames < this.quantity) {
            this.$store.commit("ALERT_MESSAGE", {
              msg: this.$t("notEnoughFrames"),
            });
          }
          this.gaItems = {
            price: this.currentValue,
            quantity: this.blockedFrames.length,
            currency: this.currency,
            item_id: `${this.id}`,
            item_name: `${this.title} by ${this.artist}`,
          };
          analytics.logEvent("add_to_cart", {
            currency: this.currency,
            value: this.subtotal,
            items: [this.gaItems],
          });
        } else if (this.transactionState === 0) {
          console.log("No frames available getting frames");
        }
        // this.$store.commit('setCheckoutFrames', this.selectedFrames);
      });
    },
    forceBackHalt() {
      if (this.subFrames) this.subFrames.off("value");
      if (this.subTimeout) this.subTimeout.off("value");
      this.blockedFrames = [];
      this.subtotal = 0;
      this.taxValue = 0;
      this.totalValue = 0;
      this.empty = true;
      this.$router.push(`/${this.$store.state.lang}/buy/${this.id}`);
    },
    forceBack() {
      if (this.subFrames) this.subFrames.off("value");
      if (this.subTimeout) this.subTimeout.off("value");
      this.blockedFrames = [];
      this.subtotal = 0;
      this.taxValue = 0;
      this.totalValue = 0;
      this.empty = true;

      if (
        this.stepActive < 3 &&
        !this.isWaitingPay &&
        !this.paymentDone &&
        this.transactionState !== -1
      ) {
        this.$router.push(`/${this.$store.state.lang}/buy/${this.id}`);
      }
    },
  },
  beforeMount() {
    if (this.quantity === undefined) {
      this.empty = true;
      if (this.id) {
        this.$router.push(`/${this.$store.state.lang}/buy/${this.id}`);
      } else {
        this.$router.push(`/${this.$store.state.lang}`);
      }
    } else {
      this.session = `${Date.now()}`;
      window.onbeforeunload = function () {
        FramesDS.unblockFrames(this.$store.state.user.uid, this.session);
        return null;
      }.bind(this);

      this.$store.commit("ALERT_MESSAGE", {
        msg: this.$t("abandonCartWarning"),
      });

      this.canvasImg = `${process.env.VUE_APP_IMAGES_URL}artwork/${this.id}/${this.id}_0.jpg`;
      ArtworkDS.getArtworkInfo(this.id).then(async (artworkInfoSnapshot) => {
        this.artworkInfo = artworkInfoSnapshot.val();

        this.artist = this.artworkInfo.artist;
        this.title = this.artworkInfo.artwork;

        const artworkMetricsSnapshot = await ArtworkDS.getArtworkMetrics(
          this.id
        );
        this.artworkMetrics = artworkMetricsSnapshot.val();
        this.currentValue = this.artworkMetrics.initialFrameValue;
        this.frameValue = this.artworkMetrics.initialFrameValue;
        this.currency = this.artworkMetrics.currency;

        this.imageLoaded = true;

        let qId;
        qId = this.qx * 10 + this.qy;
        qId = qId === 0 ? "00" : qId;

        this.timeoutInterval = setTimeout(() => {
          if (this.loadingFrames) {
            this.$store.commit("ALERT_MESSAGE", {
              msg: this.$t("serverBusy"),
            });
            this.forceBackHalt();
          }
        }, 30000);

        FramesDS.blockFrames(this.$store.state.user.uid, this.session, {
          quantity: this.quantity,
          qId: qId,
          artworkId: this.id,
        })
          .then(() => {
            this.blockedFrames = [];
            this.totalValue = 0;
            this.subTimeout = FramesDS.getCartMetadata(
              this.$store.state.user.uid,
              this.session
            );
            // Once timeout is ready, start the count
            this.subTimeout.on("value", (snapshot) => {
              const metadata = snapshot.val();
              clearInterval(this.timeoutInterval);
              if (metadata && metadata.state && metadata.state.value === 0) {
                this.transactionState = metadata.state.value;
                this.loadingFrames = false;
                this.$store.commit("ALERT_MESSAGE", {
                  msg: this.$t("noFramesAvailable"),
                });
                this.loadingFrames = false;
                this.forceBack();
              } else if (metadata && metadata.state && metadata.timeout) {
                this.transactionState = metadata.state.value;

                if (this.id !== metadata.timeout.artworkId) {
                  // Buying another work from a different place. Reset
                  this.forceBack();
                  return;
                }

                if (metadata.state.value === 6) {
                  // Frames are ready

                  const timeout = metadata.timeout;

                  const timestamp = timeout.timestamp;
                  const endTime = timestamp + timeout.waitTime;
                  this.$store.commit("startCountdown", endTime);
                  this.getBlockedFrames();
                  this.ongoing = true;
                }
              } else if (this.ongoing) {
                this.forceBack();
              }
            });
          })
          .catch(() => {
            this.transactionState = -2;
            this.forceBackHalt();
            this.$store.commit("ALERT_MESSAGE", {
              msg: this.$t("serverBusy"),
            });
          });
      });
    }

    // -----------------
  },
  async beforeDestroy() {
    try {
      if (this.stepActive < 3 && this.session && this.$store.state.user.uid) {
        await FramesDS.unblockFrames(this.$store.state.user.uid, this.session);
        analytics.logEvent("remove_from_cart", {
          currency: this.currency,
          value: this.subtotal,
          items: [this.gaItems],
        });
      }
    } catch {
      console.log("system error");
    }
  },
  beforeRouteLeave(to, from, next) {
    this.to = to;
    if (this.transactionState == 0) {
      next();
    } else if (this.stepActive === 3 || this.empty) {
      this.$store.commit("ALERT_MESSAGE", "");
      next();
      return;
    } else {
      if (this.answer) {
        if (this.subFrames) this.subFrames.off("value");
        if (this.subTimeout) this.subTimeout.off("value");
        if (
          this.qx * this.qy > 0 &&
          to.path.search(`quadrants/${this.id}`) > -1 &&
          this.quantity >= 10
        ) {
          // go to quadrant selection giving previous quantity
          to.params.quantity = this.quantity;
          next();
        } else {
          next();
        }
      } else {
        this.openAdviseModal();
      }
    }

    // if (this.answer) {
    //   next();
    // } else {
    //   next(false);
    //   this.to = to;
    //   this.openAdviseModal();
    // }
  },
  // beforeMount() {
  //   window.addEventListener('beforeunload', (event) => {
  //     // event.preventDefault();
  //     event.returnValue = undefined;
  //   });
  // },
};
</script>

<style lang="scss" scoped>
.checkout-flow {
  $this: &;
  height: 100%;
  display: flex;
  flex-direction: column;

  .checkout-flow-wrapper-steps {
    display: flex;
    width: 100%;
  }

  &__layer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transform: scale(0);
    transition: transform 300ms ease-in-out;
    width: 0;
    height: 0;
    &--visible {
      width: 100%;
      opacity: 1;
      transform: scale(1);
      height: auto;
    }
    > div:first-child {
      width: 100%;
    }
  }
  &__footer {
    margin-top: auto;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    .v-btn + .v-btn {
      margin-left: 24px;
    }
  }
}
.checkout-confirm-buttons {
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    .v-btn + .v-btn {
      margin-top: 16px;
      margin-left: 0;
    }
  }
}

.min-checkout-height {
  @media only screen and (min-width: 1200px) {
    min-height: 520px;
  }
}

.checkout-advise-modal-footer {
  display: flex;
  @media only screen and (max-width: 599px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > button + button {
      margin-top: 16px;
    }
  }
  @media only screen and (min-width: 600px) {
    flex-direction: row;
    padding: 16px;
    > button + button {
      margin-left: 16px;
    }
  }
}
</style>
